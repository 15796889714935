<template>
  <div>
    <a-modal
      title="门店数量"
      :width="540"
      :visible="visible"
      @cancel="handleCancel"
    >
      <template slot="footer">
        <a-button key="back" @click="handleCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="onSubmit"
          :loading="confirmLoading"
        >
          确认
        </a-button>
      </template>
      <div>
        <a-spin :spinning="false">
          <div class="flex alcenter">
            <div class="ft14 ftw500">商户名称：</div>
            <div class="ft14 ftw500 cl-black">{{ detail.name }}</div>
          </div>

          <div class="flex alcenter mt24">
            <div class="ft14 ftw500">应用分类：</div>
            <div class="ft14 ftw500 cl-black">{{ detail.app_name }}</div>
          </div>

          <div class="flex alcenter mt24">
            <div class="ft14 ftw500">门店数量 ：</div>
            <div>
              <a-input-number
                style="width: 200px"
                v-model="detail.store_num"
                :min="0"
              />
            </div>
          </div>
        </a-spin>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    shop_id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      confirmLoading: false,
      detail: {
        name: "",
        app_name: "",
      },
      form: {
        store_num: "",
      },
    };
  },
  created() {
    this.loaddata();
  },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/showShop", {
          shop_id: this.shop_id,
        })
        .then((res) => {
          this.detail = res.detail;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
          this.loading = false;
          this.$emit("cancel");
        });
    },

    /**
     * 取消弹窗
     */
    handleCancel() {
      this.$emit("cancel");
    },

    /**
     * 提交数据
     */
    onSubmit() {
      if (this.confirmLoading == true) return;
      this.confirmLoading = true;
      this.$http
        .api("platform/admin/renewShop", {
          shop_id: this.shop_id,
          store_num: this.detail.store_num,
        })
        .then((res) => {
          this.confirmLoading = false;
          this.$message.success("操作成功", 1, () => {
            this.$emit("ok");
          });
        })
        .catch((res) => {
          this.confirmLoading = false;
          console.log(res);
        });
    },
  },
};
</script>

<style>
.renew-miniapp-inpt .ant-input {
  width: 340px;
}
</style>
